exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cakes-celebration-js": () => import("./../../../src/pages/cakes/celebration.js" /* webpackChunkName: "component---src-pages-cakes-celebration-js" */),
  "component---src-pages-cakes-christmas-js": () => import("./../../../src/pages/cakes/christmas.js" /* webpackChunkName: "component---src-pages-cakes-christmas-js" */),
  "component---src-pages-cakes-js": () => import("./../../../src/pages/cakes.js" /* webpackChunkName: "component---src-pages-cakes-js" */),
  "component---src-pages-cakes-petite-js": () => import("./../../../src/pages/cakes/petite.js" /* webpackChunkName: "component---src-pages-cakes-petite-js" */),
  "component---src-pages-cakes-stripe-product-metadata-sub-type-stripe-product-metadata-code-js": () => import("./../../../src/pages/cakes/{stripeProduct.metadata__subType}/{stripeProduct.metadata__code}.js" /* webpackChunkName: "component---src-pages-cakes-stripe-product-metadata-sub-type-stripe-product-metadata-code-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-customer-js": () => import("./../../../src/pages/checkout-customer.js" /* webpackChunkName: "component---src-pages-checkout-customer-js" */),
  "component---src-pages-checkout-fulfillment-js": () => import("./../../../src/pages/checkout-fulfillment.js" /* webpackChunkName: "component---src-pages-checkout-fulfillment-js" */),
  "component---src-pages-checkout-notes-js": () => import("./../../../src/pages/checkout-notes.js" /* webpackChunkName: "component---src-pages-checkout-notes-js" */),
  "component---src-pages-checkout-review-js": () => import("./../../../src/pages/checkout-review.js" /* webpackChunkName: "component---src-pages-checkout-review-js" */),
  "component---src-pages-checkout-success-js": () => import("./../../../src/pages/checkout-success.js" /* webpackChunkName: "component---src-pages-checkout-success-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-customise-js": () => import("./../../../src/pages/customise.js" /* webpackChunkName: "component---src-pages-customise-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-orders-[code]-js": () => import("./../../../src/pages/orders/[code].js" /* webpackChunkName: "component---src-pages-orders-[code]-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

