import React, { useState, useEffect } from "react"
import "../css/header.css"
import { Link, navigate } from "gatsby"

import logo from "../images/logo-full.svg";
import menu from "../images/menu.svg";
import cart from "../images/shopping-cart.svg";
import close from "../images/close.svg";
import usersolidcircle from "../images/user-solid-circle.svg";

import { useCartItemsContext } from "../components/cart-context"

function CakesDropdown({ isActive, onMouseEnter }) {
  return (
    <div
      className="flex flex-col 
      items-center gap-1.5 whitespace-nowrap 
      md:items-start md:absolute top-5 
      md:left-3 
      lg:left-6 
      z-10
      md:pt-[.5rem] 
      font-gotu lowercase text-[0.81rem] tracking-[.06em] text-gray-800"
      onMouseEnter={() => { onMouseEnter(true) }}
      onMouseLeave={() => { onMouseEnter(false) }}
      style={{ display: isActive ? 'flex' : 'none' }}>
      <Link
        className="animated-underline cursor-pointer w-fit"
        to='/cakes/christmas'>christmas specials</Link>
      <Link
        className="animated-underline cursor-pointer w-fit"
        to='/cakes/petite'>petite cakes</Link>
      <Link
        className="animated-underline cursor-pointer w-fit"
        to='/cakes/celebration'>celebration cakes</Link>
    </div>
  )
}

function NavLink({ to, children, closeMenu }) {
  return (
    <li className="font-gotu lowercase text-center text-[0.83rem] tracking-[.07em] text-gray-800 animated-underline py-2 md:mx-3 lg:mx-6 md:py-0">
      <Link to={to} onClick={() => closeMenu(true)} className="whitespace-nowrap">
        {children ?? to}
      </Link>
    </li>
  )
}

function LoginLink() {
  return (
    <li>
      <Link to="/" className="hidden md:block md:mx-3 lg:mx-9">
        <img src={usersolidcircle} className="App-user-icon" alt="User Login" />
      </Link>
    </li>
  )
}

function HomeLogoLink() {
  return (

    <Link to="/" className=" min-h-[calc(3.08rem)]">
      <img src={logo} className="App-logo mx-auto" alt="logo" />
    </Link>

  )
}


const Header = () => {
  const [menuClosed, toggleMenuClosed] = useState(() => true);
  const cartItems = useCartItemsContext();

  const [isCakeDropdown, setIsCakeDropdown] = useState(false);

  const toggleCakeDropdown = (state) => {
    setIsCakeDropdown(state);
  }

  return (
    <header className="App-header py-4 lg:pb-6 lg:pt-6">
      <div className="flex justify-between items-center md:hidden">
        <Link to="/cart" className="min-w-[5rem] pl-1">
          <div className="flex">
            <img src={cart} className="App-user-icon" alt="Cart" /><span className="inline-block align-top text-sm">({cartItems.length})</span>
          </div>
        </Link>
        <HomeLogoLink />
        <div className="min-w-[5rem] text-right pr-1">
          <button onClick={() => toggleMenuClosed((state) => !state)}>
            <img src={menuClosed ? menu : close} className="App-user-icon" alt="Menu" />
          </button>
        </div>
      </div>
      <ul className={`${menuClosed ? "hidden md:flex" : "flex"} flex-col md:flex-row pt-8 md:pt-0 divide-y divide-dashed divide-pas-orange md:divide-y-0 md:items-center md:pb-4"`}>
        <div className="basis-5/12 flex justify-start w-full">
          <div className={`${menuClosed ? "hidden md:flex" : "flex"} 
           w-full 
           flex-col md:flex-row 
           pt-8 md:pt-0 md:-ml-2
           divide-y divide-dashed divide-pas-orange md:divide-y-0 
           md:items-center"`}>
            <NavLink to="/" closeMenu={toggleMenuClosed}>Home</NavLink>

            <div className="relative flex flex-col md:flex-start justify-center">
              <span
                closeMenu={toggleMenuClosed}
                onMouseEnter={() => { if (window.innerWidth > 640) toggleCakeDropdown(true) }}
                onMouseLeave={() => { if (window.innerWidth > 640) toggleCakeDropdown(false) }}
                onClick={() => { if (window.innerWidth <= 640) toggleCakeDropdown(!isCakeDropdown) }}
                className="font-gotu lowercase text-center text-[0.83rem] tracking-[.07em] text-gray-800 animated-underline py-2 md:mx-3 lg:mx-6 md:py-0 cursor-pointer"
              >shop</span>
              <CakesDropdown isActive={isCakeDropdown} onMouseEnter={toggleCakeDropdown} />
            </div>
            <NavLink to="/customise" closeMenu={toggleMenuClosed}>Customise</NavLink>

            <NavLink to="/gallery" closeMenu={toggleMenuClosed}>Gallery</NavLink>
          </div>
        </div>
        <div className="basis-2/12  hidden md:block md:grow"><HomeLogoLink /></div>
        <div className="basis-5/12 flex justify-end w-full">
          <div className={`${menuClosed ? "hidden md:flex" : "flex"} 
           w-full 
           flex-col md:flex-row md:justify-end 
           md:pt-0 md:-mr-2
           md:items-center            
           divide-y divide-dashed divide-pas-orange md:divide-y-0           
           "`}>
            <NavLink to="/about" closeMenu={toggleMenuClosed}>About Us</NavLink>
            <NavLink to="/faqs" closeMenu={toggleMenuClosed}>FAQS</NavLink>
            <NavLink to="/contact" closeMenu={toggleMenuClosed}>Contact</NavLink>
            <NavLink to="/cart" closeMenu={toggleMenuClosed}>Cart({cartItems.length})</NavLink>
          </div>
        </div>
      </ul>
    </header>
  )
}

export default Header
